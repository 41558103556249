'use strict';

import TopPlaces from './constants/top_places';

export class PlaceAddress {
  constructor(place) {
    this.components = place.address_components;
  }

  getRecord(type, returnRecord) {
    const result = _.find(this.components, { types: [type] });

    if (!result || returnRecord) {
      return result;
    }

    return result.long_name;
  }
}

export class PlaceFinder {
  constructor(city, headerTxt) {
    this._autocomplete = new google.maps.places.AutocompleteService();
    this._placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this._geocoder = new google.maps.Geocoder();
    this._requestID = 0;
    this._search = '';
    this._places = [];
    this._placeIndex = 0;
    this._searchEl = null;
    this._isCity = !!city;
    this.selectedResult = {};
    this.onPlaceChanged = null;
    this.headerTxt = headerTxt;
    this.locationPrefill = _.find(_.get(window, 'prefillPlaces', {}), { term: window.userCity });
    this.submitPrefill();
  }

  bindTo(el) {
    this._searchEl = el;
    this.addEvents();
  }

  addEvents() {
    const specialKeys = [27, 13, 38, 40];
    const isCity = this._isCity;

    // This debounce searches once you stop typing for 275ms
    this.debouncedGetPlacePredictions = _.debounce(this.getResults.bind(this), 275);

    // This throttle searches once every 275ms
    this.throttledGetPlacePredictions = _.throttle(this.getResults.bind(this), 275);
    this._searchEl.addEventListener('keyup', (e) => {
      const searchVal = this._searchEl.value;

      if (specialKeys.indexOf(e.which) > -1) {
        this.handleKeyboardEvents(e.which);
        return;
      }

      if (searchVal) {
        this.search(searchVal, isCity);
      } else {
        PlaceFinder.destroyDropdown();
        this._searchEl.classList.remove('results');
      }
    });

    this._searchEl.addEventListener('blur', () => {
      if (this._places.length && this._searchEl.value !== '') {
        this.setPlace(this._places[0], 0);
      } else {
        PlaceFinder.destroyDropdown();
        this._searchEl.classList.remove('results');
      }
    });

    this._searchEl.addEventListener('focus', this.clearSearch.bind(this));

    this._searchEl.addEventListener('click', () => {
      if (_.isEmpty(this._places) && TopPlaces && !jQuery(document.body).find('#search-results-dropdown').length) {
        this._places = TopPlaces;
        this.createDropdown(TopPlaces, /* isTopSearch = */ true);
        analytics.track('Listings Search Suggestions Viewed', PlaceFinder.createAnalyticsPayload('', TopPlaces, /* isTopSearch = */ true));
      }
    });
  }

  handleKeyboardEvents(key) {
    const specialEvents = {
      13: () => {
        this.setPlace(this._places[this._placeIndex], this._placeIndex);
        this._searchEl.blur();
      },
      27: this.clearSearch,
      38: this.moveUpResults,
      40: this.moveDownResults,
    };

    if (specialEvents[key]) {
      specialEvents[key].call(this);
    }
  }

  moveDownResults() {
    this._placeIndex += 1;

    if (this._placeIndex > (this._places.length - 1)) {
      this._placeIndex = 0;
    }

    this.highlightResult();
  }

  moveUpResults() {
    this._placeIndex -= 1;

    if (this._placeIndex < 0) {
      this._placeIndex = (this._places.length - 1);
    }

    this.highlightResult();
  }

  highlightResult() {
    const dropdown = document.getElementById('search-results-dropdown');

    if (dropdown) {
      const previousRow = dropdown.querySelector('.active');
      const resultRow = dropdown.querySelector('[data-results-index="' + this._placeIndex + '"]');

      if (previousRow) {
        previousRow.classList.remove('active');
      }

      if (resultRow) {
        resultRow.classList.add('active');
      }
    }
  }

  searchCity(string) {
    return this.search(string, true);
  }

  handleResults(reqID, queryString, results) {
    // Do not continue if this is not the CURRENT request.
    if (reqID !== this._requestID) {
      return;
    }

    if (!results) {
      results = [];
    }

    results = results.map((v) => ({
      term: v.terms[0].value,
      term_sub: v.terms.slice(1).map((_v) => _v.value).join(', '),
      term_bold: v.terms[0].value.replace(new RegExp(queryString, 'gi'), (_v) => `<b>${_v}</b>`),
      address: v.terms.map((_v) => _v.value).join(', '),
      place_id: v.place_id
    }));

    this._places = results;

    // tracking results list
    analytics.track('Listings Search Suggestions Viewed', PlaceFinder.createAnalyticsPayload(queryString, results));

    this.renderResults.call(this, results);
  }

  getResults(queryString, types) {
    // Because Google does not return the request for us to cancel, this is the easiest way to do so
    // eslint-disable-next-line no-multi-assign
    const reqID = this._requestID += 1;
    this._autocomplete.getPlacePredictions({
      input: queryString,
      types: types
    }, this.handleResults.bind(this, reqID, queryString));
  }

  search(string, isCity, isPrefill) {
    // Don't search if there is no change
    if (this._search === string) {
      return;
    }

    this._search = string;

    let types = ['address'];
    if (isCity) {
      types = ['geocode'];
    }

    if (!isPrefill) {
      // At the beginning of the search we want it to be as snappy as possible,
      // and we also want to perform the search immediately after someone enters
      // a space character as that is likely a word boundary.
      if (string.length < 4 || _.endsWith(string, ' ')) {
        // Get the updated results quickly
        this.throttledGetPlacePredictions(string, types);
      } else {
        // Take a bit more time
        this.debouncedGetPlacePredictions(string, types);
      }
    }
  }

  static createAnalyticsPayload(queryString, results, isTopSearch) {
    const payload = {
      is_top_search: isTopSearch,
      query_typed_in: queryString
    };

    results.forEach(function (place, index) {
      payload['suggestion_' + (index + 1)] = place.address;
    });

    return payload;
  }

  renderResults(results) {
    PlaceFinder.destroyDropdown();

    if (results) {
      this.createDropdown(results);
    }
  }

  createDropdown(places, isTopSearch) {
    const dropdown = document.createElement('div');
    dropdown.id = 'search-results-dropdown';
    dropdown.classList.add('search-results');

    if (this.headerTxt && isTopSearch) {
      const headerRow = document.createElement('div');
      headerRow.innerHTML = this.headerTxt;
      headerRow.classList.add('search-result-header');
      headerRow.addEventListener('mousedown', cancelInputBlur);
      headerRow.addEventListener('touchstart', cancelInputBlur);
      dropdown.appendChild(headerRow);
    }

    function cancelInputBlur(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    for (let i = 0; i < places.length; i += 1) {
      const place = places[i];
      const placeItem = document.createElement('div');

      placeItem.classList.add('search-result-item');
      placeItem.setAttribute('data-place-id', place.place_id);
      placeItem.setAttribute('data-results-index', `${i}`);
      placeItem.innerHTML = place.term_bold + ' <span class="place-subtext">' + place.term_sub + '</span>';

      placeItem.addEventListener('mousedown', selectPlace(this, place, i, isTopSearch));
      placeItem.addEventListener('touchstart', selectPlace(this, place, i, isTopSearch));
      dropdown.appendChild(placeItem);
    }

    function selectPlace(self, selectedPlace, j, topSearch) {
      return () => {
        self.setPlace.call(self, selectedPlace, j, topSearch);
        /* eslint-disable no-restricted-globals */
        event.preventDefault();
        event.stopPropagation();
      };
    }

    this._searchEl.classList.add('results');
    this._searchEl.parentNode.appendChild(dropdown);
    this._placeIndex = 0;
    this.highlightResult();
  }

  static destroyDropdown() {
    const emptyDropdown = document.getElementById('search-results-dropdown');

    if (emptyDropdown && emptyDropdown.parentNode) {
      emptyDropdown.parentNode.removeChild(emptyDropdown);
    }
  }

  submitPrefill() {
    if (!this.locationPrefill) {
      return;
    }

    analytics.track('Listings Search Prefill Displayed', {
      cityPrefill: window.userCity
    });

    this.search(this.locationPrefill, true, true);
    this.getPlace(this.locationPrefill, false, true);
  }

  setPlace(place, position, isTopSearch) {
    if (!place) {
      return;
    }

    /* track the suggestion chosen by the user */
    analytics.track('Listings Search Suggestions Clicked', {
      is_top_search: isTopSearch,
      query_typed_in: this._search,
      selected_suggestion: place.address,
      selected_suggestion_position: (position + 1)
    });

    this._places = [];
    this._searchEl.value = place.term + ' ' + place.term_sub;
    this._searchEl.classList.remove('results');
    this.getPlace(place, true, false);

    PlaceFinder.destroyDropdown();
  }

  clearSearch() {
    this._searchEl.value = '';
    this._search = '';
    this.selectedResult = {};
    this._searchEl.classList.remove('results');
    this._places = [];

    PlaceFinder.destroyDropdown();
  }

  getPlace(place, doSubmit, isPrefill) {
    this._placesService.getDetails({ placeId: place.place_id }, (placeResult) => {
      const payload = this.extractPayload(placeResult);

      if (placeResult.geometry.viewport) {
        this.selectedResult = PlaceFinder.addGeometryData(payload, placeResult.geometry);
        this.selectedResult.isPrefill = isPrefill;
      } else {
        this._geocoder.geocode({ placeId: place.place_id }, (geoResult) => {
          this.selectedResult = PlaceFinder.addGeometryData(payload, _.head(geoResult).geometry);
          this.selectedResult.isPrefill = isPrefill;
        });
      }

      if (doSubmit && typeof this.onPlaceChanged === 'function') {
        this.onPlaceChanged();
      }
    });
  }

  static addGeometryData(payload, geometry) {
    const bounds = PlaceFinder.extractBounds(geometry);

    return _.merge({}, payload, {
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
      lat_l: bounds.ne.lat(),
      lat_g: bounds.sw.lat(),
      lng_l: bounds.ne.lng(),
      lng_g: bounds.sw.lng()
    });
  }

  static extractBounds(geometry) {
    return {
      ne: geometry.viewport.getNorthEast(),
      sw: geometry.viewport.getSouthWest()
    };
  }

  extractPayload(place) {
    const placeAddress = new PlaceAddress(place);

    const streetNumber = placeAddress.getRecord('street_number');
    const route = placeAddress.getRecord('route');
    const neighborhood = placeAddress.getRecord('neighborhood');
    const city = placeAddress.getRecord('locality');
    const postalCode = placeAddress.getRecord('postal_code');
    const countryRow = placeAddress.getRecord('country', true);
    const stateRow = placeAddress.getRecord('administrative_area_level_1', true);

    const state = stateRow && stateRow.long_name;
    const stateShort = stateRow && stateRow.short_name;
    const country = countryRow && countryRow.long_name;
    const countryShort = countryRow && countryRow.short_name;

    return {
      placeId: place.place_id,
      term: this._search,
      address: place.formatted_address,
      street_number: streetNumber,
      route: route,
      neighborhood: neighborhood,
      city: city,
      state: state,
      state_short: stateShort,
      country: country,
      country_short: countryShort,
      postal_code: postalCode,
      data: place
    };
  }
}
